<template>
    <div class="row justify-content-center align-items-center vh-100 w-100 m-0">
        <div class="col-12 row justify-content-center align-items-center p-0">
            <div class="col-12 col-md-10 col-lg-8 col-xxl-6 p-5 form-box">
                <div class="d-flex flex-wrap justify-content-between">
                    <h2>{{ $t("login.title") }}</h2>
                    <ChangeLang class="mb-2"/>
                </div>
                <div v-if="invalid" class="alert alert-danger mt-3 mb-1" role="alert">
                    {{ $t("login.invalid") }}
                </div>
                <label for="username" class="form-label my-2">{{ $t("login.username") }}</label>
                <input type="text"
                id="username"
                class="form-control"
                ref="username"
                v-model="username"
                :placeholder="$t('login.yourUsername')"
                @keyup.enter="login()"
                >
                <label for="password" class="form-label my-2">{{ $t("login.password") }}</label>
                <input type="password"
                id="password"
                class="form-control"
                ref="password"
                v-model="password"
                :placeholder="$t('login.yourPassword')"
                @keyup.enter="login()"
                >
                <button type="submit"
                class="mt-4 p-2 btn btn-primary w-100 form-submit"
                @click="login()"
                >
                    {{ $t("login.button") }}
                </button>
                <div class="text-center mt-3">
                    <a href="https://stary.system.drevo-hosek.cz">Stará verze systému</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChangeLang from "@/components/ChangeLang.vue"
import store from "../store";
import { CONFIG } from "@/config.js";

export default {
    components: {
        ChangeLang
    },
    data() {
        return {
            username: null,
            password: null,
            invalid: false
        }
    },
    methods: {
        login: function () {
            this.$store.getters.api.post("/auth/login",
                { "username": this.username, "password": this.password }
            ).then(
                (response) => {
                    const data = response.data;
                    if (data.error_code == 100) {
                        this.invalid = true;
                        this.$refs.password.setCustomValidity(this.$t("login.invalid"));
                        this.$refs.username.setCustomValidity(this.$t("login.invalid"));
                        this.$refs.password.reportValidity();
                        this.$refs.username.reportValidity();
                    }
                    else {
                        store.commit("saveUserProfile", response.data.user);

                        const path = this.$route.query.path;
                        if (path != null) {
                            this.$router.push(path);
                        } else {
                            this.$router.push({ name: CONFIG.afterLoginRoute });
                        }
                    }
                }
            )
        }
    },
    created: async function () {
        // try load data and possibly redirect
        this.$store.commit("getUserProfile");

        // wait for loggin in 1 second
        let attempts = 0;
        const totalTime = 2000;
        const attemptTime = 100;
        while (attempts < totalTime/attemptTime) {
            if (this.$store.getters.isLoggedIn) {
                break;
            }
            await new Promise(r => setTimeout(r, attemptTime));
            attempts++;
        }

        if (this.$store.getters.isLoggedIn) {
            const path = this.$route.query.path;
            if (path != null) {
                this.$router.replace({ path: path });
            }
            else {
                this.$router.push({ name: CONFIG.afterLoginRoute });
            }
        }
    }
}
</script>

<style lang="scss">
</style>